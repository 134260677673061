import React from 'react';
import './AreasServed.css';


const AreasServed = () => {
  return (
    <section className="areas-served">
      <h2>Areas We Serve</h2>
      <p>We proudly serve Sacramento and the surrounding counties.</p>
      <div className="map-container">
        <iframe
          title="Service Area"
          src="https://www.google.com/maps/d/embed?mid=15X-XbqJWIQ-VpbSkXH2fGtlkGUNT1kM&ehbc=2E312F"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>

  );
};

export default AreasServed;
