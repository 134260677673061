import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Services from './components/Services/Services';
import Reviews from './components/Reviews/Reviews';
import Contact from './components/Contact/Contact';
import RecentWork from './components/RecentWork/RecentWork';
import About from './components/About/About';
import Quote from './components/Quote/Quote';
import AreasServed from './components/AreasServed/AreasServed';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Services />
        <About />
        <Reviews />
        <Contact />
        <AreasServed />
        <RecentWork />
        <Quote />
      </main>
      <Footer />
    </div>
  );
}

export default App;


// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
// import Services from './components/Services/Services';
// import Reviews from './components/Reviews/Reviews';
// import Contact from './components/Contact/Contact';
// import RecentWork from './components/RecentWork/RecentWork';
// import About from './components/About/About';
// import Quote from './components/Quote/Quote';
// import AreasServed from './components/AreasServed/AreasServed';
// import './App.css';

// const MyRouteComponent = () => (
//   <div>
//       <Services />
//       <About />
//       <Reviews />
//       <RecentWork />
//       <Contact />
//       <AreasServed />
//   </div>
// );

// function App() {
//   return (
//     <Router>
//     <div className="App">
//       <Header />
//       <main>
//         <Routes>
//             <Route path="/" element={<MyRouteComponent />} />
//             <Route path="/quote" element={<Quote />} />
//         </Routes>

//       </main>
//       <Footer />
//     </div>
//     </Router>
//   );
// }

// export default App;
