import React from 'react';
import './Reviews.css';
import fenceImage1 from '../../assets/redwood.jpg';
import concreteImage2 from '../../assets/driveway.jpg';
import yardImage1 from '../../assets/after.jpeg';
import yardImage2 from '../../assets/backafter.jpeg';

const Reviews = () => {
  const reviews = [
    { name: "Jane Doe", image: fenceImage1,  text: "Great service! My garden looks amazing!" },
    { name: "John Smith", image: concreteImage2, text: "Professional and reliable, I highly recommend them." },
    { name: "Emily Davis", image: yardImage1, text: "They transformed my backyard completely!" },
    { name: "Rachel Green", image: yardImage2, text: "They transformed my landscape completely!" },
  ];

  return (
    <section className="reviews" id="reviews">
      <div className="reviews-overlay">
        <h2>What Our Clients Say</h2>
        <div className="reviews-container">
          {reviews.map((review, index) => (
            <div className="review-card" key={index}>
              <img src={review.image} alt={`Review ${index}`} />
              <p>"{review.text}"</p>
              <span>- {review.name}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
