import React from 'react';
import './Services.css';
import houselandscape from '../../assets/houselandscape.jpg';

const Services = () => {
  const handleScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section
  className="services"
  id="services"
  style={{ backgroundImage: `url(${houselandscape})` }}
>
  <div className="services-overlay">
    <h2>Our Services</h2>
    <p>
      We offer a wide range of landscaping services to meet your needs, ensuring your outdoor space is both beautiful and functional.
    </p>
    <ul>
      <li>Premium Fencing</li>
      <li>Concrete Installation</li>
      <li>Whole Yard Design and Installation</li>
    </ul>
    <div className="button-container">
      <button className="solid-button" onClick={() => handleScroll('contact')}>
        Contact Now
      </button>
      <button className="hollow-button" onClick={() => handleScroll('quote')}>
        Estimate My Fence
      </button>
    </div>
  </div>
</section>
  );
};

export default Services;
