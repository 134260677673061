import React, { useState, useEffect, useRef } from 'react';
import './RecentWork.css';
import fenceImage1 from '../../assets/redwood.jpg';
import fenceImage2 from '../../assets/vinylfence.jpg';
import concreteImage1 from '../../assets/stampedconcrete.jpg';
import concreteImage2 from '../../assets/driveway.jpg';
import yardImage1 from '../../assets/before.jpeg';
import yardImage2 from '../../assets/after.jpeg';
import yardImage3 from '../../assets/backbefore.jpeg';
import yardImage4 from '../../assets/backafter.jpeg';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'; // Arrow icons

const workData = {
  fences: [
    { title: "Wooden Fence", images: [fenceImage1, fenceImage1] },
    { title: "Vinyl Fence", images: [fenceImage2, fenceImage2] },
  ],
  concrete: [
    { title: "Patio Installation", images: [concreteImage1, concreteImage1] },
    { title: "Driveway", images: [concreteImage2, concreteImage2] },
  ],
  landscaping: [
    { title: "Backyard Makeover", images: [yardImage1, yardImage2] },
    { title: "Front Yard Design", images: [yardImage3, yardImage4] },
  ],
};

const RecentWork = () => {
  const [currentCategory, setCurrentCategory] = useState('fences'); // Track category
  const [isSliding, setIsSliding] = useState(false); // Prevent clicks during slide transition
  const carouselRef = useRef(null);

  const categories = Object.keys(workData);

  const handlePrev = () => {
    if (isSliding) return; // Prevent multiple clicks during transition
    setIsSliding(true);
    const currentIndex = categories.indexOf(currentCategory);
    const prevIndex = (currentIndex - 1 + categories.length) % categories.length;
    setCurrentCategory(categories[prevIndex]);
  };

  const handleNext = () => {
    if (isSliding) return;
    setIsSliding(true);
    const currentIndex = categories.indexOf(currentCategory);
    const nextIndex = (currentIndex + 1) % categories.length;
    setCurrentCategory(categories[nextIndex]);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsSliding(false), 600); // Control sliding duration
    return () => clearTimeout(timeout); // Cleanup timeout
  }, [currentCategory]);

  return (
    <section className="recent-work" id="recent-work">
      <h2>Our Recent Work</h2>
      <div className="carousel">
        <button className="arrow left" onClick={handlePrev} disabled={isSliding}>
          <FiChevronLeft size={40} />
        </button>

        <div className="work-container" ref={carouselRef}>
          {workData[currentCategory].map((work, index) => (
            <div className={`work-item ${isSliding ? 'sliding' : ''}`} key={index}>
              <h3>{work.title}</h3>
              <div className="images">
                {work.images.map((image, imgIndex) => (
                  <img src={image} alt={`${work.title} ${imgIndex}`} key={imgIndex} />
                ))}
              </div>
            </div>
          ))}
        </div>

        <button className="arrow right" onClick={handleNext} disabled={isSliding}>
          <FiChevronRight size={40} />
        </button>
      </div>

      <div className="carousel-indicators">
        {categories.map((category, index) => (
          <span
            key={index}
            className={`dot ${currentCategory === category ? 'active' : ''}`}
            onClick={() => setCurrentCategory(category)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default RecentWork;
