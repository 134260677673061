import React from 'react';
import './Header.css';

const Header = () => {

  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className="header">
      <h1 className="header-title">Property Care</h1>
      <nav className="nav-links">
        <button onClick={() => handleScroll('services')}>Services</button>
        <button onClick={() => handleScroll('reviews')}>Reviews</button>
        <button onClick={() => handleScroll('contact')}>Contact Us</button>

      </nav>
    </header>
  );
};

export default Header;
