import React, { useState, useEffect } from 'react';
import woodfence from '../../assets/woodfence.jpg';
import metalfence from '../../assets/metalfence.jpg';
import vinylfence from '../../assets/vinylfence.jpg';
import otherfence from '../../assets/otherfence.jpeg';
import './Quote.css';

const Quote = () => {
  const [formData, setFormData] = useState({
    type: '',
    style: '',
    length: '',
    gateNeeded: false,
    gateSize: '',
  });
  const [selectedFence, setSelectedFence] = useState('');
  const [quote, setQuote] = useState(null);

  const fences = [
    { type: 'Wood', img: woodfence, value: 'wood' },
    { type: 'Metal', img: metalfence, value: 'metal' },
    { type: 'Vinyl', img: vinylfence, value: 'vinyl' },
    { type: 'Other', img: otherfence, value: 'other' },
  ];

  const handleFenceSelect = (type) => {
    setFormData({ ...formData, type });
    setSelectedFence(type);
  };

  const handleChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    setFormData({
      ...formData,
      [name]: inputType === 'checkbox' ? checked : value,
    });
  };

  const calculateQuote = () => {
    const { type, style, length, gateNeeded, gateSize } = formData;

    // Updated calculation logic
    const basePrice = 40; // $40 per foot
    const typeMultiplier = type === 'wood' ? 1.2 : type === 'metal' ? 2.5 : 1;
    const styleMultiplier = style === 'decorative' ? 1.5 : 1;
    let totalCost = basePrice * (length || 0) * typeMultiplier * styleMultiplier;

    if (gateNeeded) {
      totalCost += (gateSize || 0) * 65; // $65 per foot for gate size
    }

    setQuote(totalCost.toFixed(2));
  };

  // Recalculate quote whenever form data changes
  useEffect(() => {
    calculateQuote();
  }, [formData]);

  return (
    <section className="quote" id="quote">
      <h2>Fence Price Quoting</h2>

      <h3>Choose a Fence Type</h3>
      <div className="fence-options">
        {fences.map((fence) => (
          <div
            key={fence.value}
            className={`fence-option ${selectedFence === fence.value ? 'selected' : ''}`}
            onClick={() => handleFenceSelect(fence.value)}
          >
            <p>{fence.type}</p>
            <img src={fence.img} alt={fence.type} />
          </div>
        ))}
      </div>
      <form  onSubmit={(e) => e.preventDefault()}>
        <label>Fence Style</label>
        <select name="style" value={formData.style} onChange={handleChange} required>
          <option value="">Select Style</option>
          <option value="standard">Standard</option>
          <option value="decorative">Decorative</option>
        </select>

        <label>Fence Length (ft)</label>
        <input
          type="number"
          name="length"
          value={formData.length}
          onChange={handleChange}
          required
        />
        <div className="gate-toggle">
          <label>Do you need a gate?</label>
          <label className="switch">
            <input
              type="checkbox"
              name="gateNeeded"
              checked={formData.gateNeeded}
              onChange={handleChange}
            />
            <span className="slider"></span>
          </label>
        </div>

        {formData.gateNeeded && (
          <div className="gate-size">
            <label htmlFor="gateSize">Gate Size (ft)</label>
            <input
              type="number"
              id="gateSize"
              name="gateSize"
              value={formData.gateSize}
              onChange={handleChange}
            />
          </div>
        )}

        <button type="submit">Get Quote</button>
      </form>

      {quote && <p>The estimated cost for your fence is: ${quote}</p>}
    </section>
  );
};

export default Quote;
