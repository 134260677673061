import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '', // New field
    preferredContact: [], // New field for contact preferences
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        preferredContact: checked
          ? [...prevData.preferredContact, value]
          : prevData.preferredContact.filter((method) => method !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailData = {
      ...formData,
      preferredContact: formData.preferredContact.join(', '), // Format for email
    };

    emailjs
      .send('service_kcxu3nk', 'template_oy0cc8v', emailData, 'TKYaZ6qNn4vEhIN5d')
      .then(() => {
        console.log('Email sent successfully');
        setSubmitted(true);
      })
      .catch((error) => console.error('Error sending email:', error));
  };

  return (
    <section className="contact" id="contact">
      <div className="contact__info">
        <h2>It's Time To Enjoy Your Perfect Yard</h2>
          <div className="contact_info_text">
            <p>Give us a call or reach out and we will come give you a <strong>FREE Quote</strong> for whatever you need!</p>
          </div>
        <div className="contact__address-container">
          <div className="contact__address">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
            <p>2518 Whitewater Way, Sacramento, CA</p>
          </div>
          <div className="contact__address">
            <FontAwesomeIcon icon={faMobileAlt} className="icon" />
            <p>(916) 123-4567</p>
          </div>
          <div className="contact__address">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            <p>info@propertycare.com</p>
          </div>
        </div>
      </div>

      {submitted ? (
        <p className="thank-you">Thank you! We will get back to you soon.</p>
      ) : (
        <form className="contact__form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone (Optional)</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="123-456-7890"
            />
          </div>

          <div className="checkbox-group">
            <label>Preferred Method of Contact</label>
            <div className="checkbox-group">
            <div>
              <input
                type="checkbox"
                id="email-contact"
                name="preferredContact"
                value="Email"
                onChange={handleChange}
              />
              <label htmlFor="email-contact">Email</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="call-contact"
                name="preferredContact"
                value="Call"
                onChange={handleChange}
              />
              <label htmlFor="call-contact">Call</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="text-contact"
                name="preferredContact"
                value="Text"
                onChange={handleChange}
              />
              <label htmlFor="text-contact">Text</label>
            </div>
          </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              placeholder='I need to replace a fence, Please give me a call!'
              required
            />
          </div>

          <button type="submit" className="cta">Submit</button>
        </form>
      )}
    </section>
  );
};

export default Contact;
