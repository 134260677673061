import React from 'react';
import './About.css';
import teamPhoto from '../../assets/team.jpeg';

const About = () => {
  return (
    <section className="about" id="about">
      <div className="banner">
        <h1>
         <span className="underline">Licensed — Insured — Bonded</span>
        </h1>
      </div>
      <div className="about-container">
        <img src={teamPhoto} alt="Our Team" />
        <div className="about-text">
          <h2>About Us</h2>
          <p>
            At Property Care, we are passionate about transforming outdoor spaces
            into beautiful, functional landscapes. With over a decade of experience,
            our team of experts delivers top-tier landscaping services that exceed
            expectations every time.
          </p>
          <p>
            Whether you're looking for a garden overhaul, lawn care, or landscape design, 
            we are committed to making your dream outdoor space a reality.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
